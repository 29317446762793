import { loadSlider } from '../shared/utils/swiper';

// This should correspond to the Schema of sections/featured-collection.liquid
const sectionSettingsExample = {
  title: 'Hobbii products',
  description: 'Collection description',
  show_description: false,
  collection: 'hobbii-yarn',
  show_view_all: true
};

type SectionSettings = typeof sectionSettingsExample;

const getSectionSettingsFromDOMElement = (el: HTMLElement): SectionSettings => {
  const { sectionSettings } = el.dataset;

  let settings: SectionSettings | null = null;

  if (!el.id)
    throw new Error('No id attribute assigned for featured collection section');

  if (!sectionSettings)
    throw new Error(
      `No settings found for featured collection section with id "${el.id}"`
    );

  settings = JSON.parse(sectionSettings);

  if (!settings || !settings.collection)
    throw new Error(
      `No collection configured for featured collection section with id "${el.id}"`
    );

  return settings;
};

const fetchCollectionSectionHtml = async (
  collectionHandle: string
): Promise<string> => {
  // Using the section rendering API to fetch the slider content
  // To get the context of the collection, we load the section using the collection's url
  // https://shopify.dev/docs/api/ajax/section-rendering
  const sectionLiquidFileName = 'collection-slider-async';
  const request = await fetch(
    `${window.Shopify.routes.root}collections/${collectionHandle}?sections=${sectionLiquidFileName}`
  );
  const response = await request.json();
  const section = response[sectionLiquidFileName];
  if (!section)
    throw new Error(
      `Section file "/sections/${sectionLiquidFileName}.liquid" not found in response.`
    );

  return section;
};

const loadCollection = async (collectionElm: HTMLElement) => {
  try {
    const settings = getSectionSettingsFromDOMElement(collectionElm);
    const section = await fetchCollectionSectionHtml(settings.collection);
    // Override section content (loading placeholder)
    collectionElm.innerHTML = section;

    // We use the section id from .js-async-collection to scope the slider initialization
    // to avoid initializing the slider multiple times
    loadSlider(`#${collectionElm.id} .js-collection-slider-async`, {});
  } catch (error) {
    // Fail silently
    console.error(error);
    return;
  }
};

const setupCollectionsIntersectionObserver = () => {
  const collections = document.querySelectorAll('.js-async-collection');
  if (!collections.length) return;

  const onIntersection: IntersectionObserverCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const collectionElm = entry.target as HTMLElement;
        loadCollection(collectionElm);
        observer.unobserve(collectionElm);
      }
    });
  };

  const observer = new IntersectionObserver(onIntersection, {
    rootMargin: '0px 0px 400px 0px'
  });

  collections.forEach((element) => {
    observer.observe(element);
  });
};

setupCollectionsIntersectionObserver();
